import { defineStore } from "pinia"
import { getAction } from "@/server/request/service"
import { getListUrl } from "@/utils/queryUrl";
import materials from '@/assets/materials.jpg';

const useMain = defineStore('useMain', {
    /**
     * 存储全局状态
     * 1.必须是箭头函数: 为了在服务器端渲染的时候避免交叉请求导致数据状态污染
     * 和 TS 类型推导
     */
    state: () => {
        return {
            // 默认路由
            defaultMenu: [{
                // 首页
                name: "home",
                title: "首页",
                icon: '',
                path: '/home',
                component: "layouts/Basic",
                children: [
                    {
                        // 首页
                        name: "homepage",
                        title: "首页",
                        icon: 'HomeOutlined',
                        path: '/home/homepage',
                        component: "home/Home",
                    },
                    {
                        // Tkpm
                        name: "tkpm",
                        title: "TKPM结构正向设计",
                        icon: 'InsertRowAboveOutlined',
                        path: "/home/tkpm",
                        component: 'tkpm/Tkpm'
                    },
                    {
                        // 构件计算
                        name: "componentCalculation",
                        title: "常用构件计算",
                        icon: 'ProjectOutlined',
                        path: "/home/componentCalculation",
                        component: 'equipment/EquipmentManifest',
                    },
                    {
                        // 有限元计算
                        name: "finiteElements",
                        title: "有限元计算",
                        icon: 'FundProjectionScreenOutlined',
                        path: "/home/finiteElements",
                        component: 'finiteElements/FiniteElements',
                    },
                    {
                        // revit钢筋
                        name: "revit",
                        title: "钢筋施工插件",
                        icon: 'InsertRowAboveOutlined',
                        path: "/home/revit",
                        component: 'revit/Revit'
                    },
                    {
                        // 钢筋翻样
                        name: "rebarMaterials",
                        title: "钢筋翻样表",
                        icon: 'InsertRowAboveOutlined',
                        path: "/home/rebarMaterials",
                        component: 'rebar/RebarMaterials'
                    },
                    {
                        // 钢筋加工
                        name: "rebarProcessing",
                        title: "钢筋加工",
                        icon: 'InsertRowAboveOutlined',
                        path: "/home/rebarProcessing",
                        component: 'rebar/RebarProcessing'
                    },
                ]
            },
            {
                // 登录页
                name: "login",
                title: "登录",
                icon: '',
                path: '/login/:type',
                component: "login/Login",
                children: []
            },
            {
                // 微信登录跳转页
                name: "wecharlogin",
                title: "微信登录",
                icon: '',
                path: '/wecharlogin',
                component: "login/Test",
                children: []
            },
            ],
            // 管理员路由
            adminMenu: [{
                parent: "home",
                name: "feedBack",
                title: "反馈",
                icon: 'InsertRowAboveOutlined',
                path: "/home/feedBack",
                component: 'feedback/FeedBack'
            }],
            // 登录成功路由
            loginMenu: [{
                parent: "home",
                name: "user",
                title: "用户中心",
                icon: 'InsertRowAboveOutlined',
                path: "/home/userInfo",
                component: 'user/UserInfo'
            },
            {
                parent: "home",
                name: "pay",
                title: "会员续费",
                icon: 'InsertRowAboveOutlined',
                path: "/home/pay",
                component: 'user/UserPay'
            }],
            rebarMenu: [
                {
                    id: "home",
                    title: "功能介绍",
                    icon: "file-done",
                    url: "",
                    children: [
                        {
                            id: "home",
                            name: "home",
                            title: "教学视频",
                            url: ""
                        }
                    ]
                },
                {
                    id: "materials",
                    title: "料单管理",
                    icon: "file-done",
                    url: "",
                    children: [
                        {
                            id: "example",
                            name: "example",
                            title: "钢筋翻样",
                            url: "/materials/example"
                        },
                        {
                            id: "manage",
                            name: "manage",
                            title: "图纸识别",
                            url: "/resource/manage",
                        },
                        {
                            id: "identify",
                            name: "identify",
                            title: "识别管理",
                            url: '/materials/identify',
                        },
                        {
                            id: "diagrams",
                            name: "diagrams",
                            title: "简图管理",
                            url: '/materials/diagrams',
                        },
                        
                    ]
                },
                {
                    id: "setting",
                    title: "选项设置",
                    icon: "setting",
                    url: "",
                    children: [
                        {
                            id: "specification",
                            name: "specifications",
                            title: "型号规格",
                            url: "/setting/specification",
                        },
                        {
                            id: "diameter",
                            name: "diameter",
                            title: "直径及重量",
                            url: "/setting/diameter",
                        },
                        {
                            id: "connect",
                            name: "connect",
                            title: "连接方式",
                            url: "/setting/connect",
                        },
                        {
                            id: "extend",
                            name: "extend",
                            title: "扣减设置",
                            url: "/setting/extend",
                        },
                        {
                            id: "font",
                            name: "font",
                            title: "字库管理",
                            url: "/setting/font",
                        },
                    ]
                },
            ],
            rebarProcessingMenu: [
                {
                    id: "produce",
                    title: "钢筋加工管理",
                    icon: "produce",
                    url: "",
                    children: [
                        {
                            id: "project",
                            name: "project",
                            title: "项目管理",
                            url: "/produce/project",
                        },
                        {
                            id: "team",
                            name: "team",
                            title: "队伍管理",
                            url: "/produce/team",
                        },
                        {
                            id: "equipment",
                            name: "equipment",
                            title: "设备管理",
                            url: "/produce/equipment",
                        },
                        {
                            id: "processing",
                            name: "processing",
                            title: "加工管理",
                            url: "/produce/processing",
                        },
                        {
                            id: "rawMaterial",
                            name: "rawMaterial",
                            title: "原料管理",
                            url: "/produce/rawMaterial",
                        },
                        {
                            id: "procurement",
                            name: "procurement",
                            title: "采购管理",
                            url: "/produce/procurement",
                        },
                        {
                            id: "automatic",
                            name: "automatic",
                            title: "自动加工",
                            url: "/produce/automatic",
                        },
                        {
                            id: "leftovers",
                            name: "leftovers",
                            title: "余料管理",
                            url: "/produce/leftovers",
                        },
                    ]
                }
            ],
            rebarList: [
                {
                    id: "example",
                    appName: "钢筋翻样",
                    url: "",
                    pic: "http://43.142.45.115:9000/famousbuild/rebarImg/rebartable3.png",
                    state: 1,
                    description: "轻松录入料单，自动计算重量和长度，支持导出excel表格，提供工作效率.",
                },
                {
                    id: "identify",
                    appName: "智能化识别钢筋",
                    url: "",
                    pic: "http://43.142.45.115:9000/famousbuild/rebarImg/rebartable2.png",
                    state: 1,
                    description: "自动设别pdf图纸中的钢筋，提取图纸中的文字信息和图像信息,更高效,快捷的方式",
                },
                {
                    id: "diagrams",
                    appName: "钢筋分段工具",
                    url: "",
                    pic: "http://43.142.45.115:9000/famousbuild/rebarImg/rebartable4.jpg",
                    state: 1,
                    description: "支持缩尺钢筋自动排列放样、支持超长钢筋断料、桩钢筋断料，更好的满足用户需求",
                },
                {
                    id: "specification",
                    appName: "参数设置",
                    url: "",
                    pic: "http://43.142.45.115:9000/famousbuild/rebarImg/setting.jpg",
                    state: 1,
                    description: "根据不同项目、料单设置不同的弯曲折减参数，支持默认参数的全自动折减长度计算",
                },
            ],
            revitList: [
                {
                    id: "1",
                    appName: "",
                    url: "/home/revit",
                    pic: "http://43.142.45.115:9000/famousbuild/revit/img/revit1.png",
                    state: 1,
                    description: "弧形构件智能配筋，简化弧形构件设计流程，提升工作效率。",
                },
                {
                    id: "2",
                    appName: "标题2",
                    url: "",
                    pic: "http://43.142.45.115:9000/famousbuild/revit/img/revit2.png",
                    state: 1,
                    description: "自动识别洞口位置，确保结构强度的同时，实现自动化切断和调整钢筋布局",
                },
                {
                    id: "3",
                    appName: "标题4",
                    url: "",
                    pic: "http://43.142.45.115:9000/famousbuild/revit/img/revit3.jpg",
                    state: 1,
                    description: "便捷的钢筋生成和属性修改，高效生成所需的钢筋配置",
                },
                {
                    id: "4",
                    appName: "标题4",
                    url: "",
                    pic: "http://43.142.45.115:9000/famousbuild/revit/img/revit5.png",
                    state: 1,
                    description: "支持常规梁、柱平法参数输入建模，自动统计钢筋的长度、重量和工艺信息，简化料单录入的过程",
                }
            ],
            windowWidth: document.body.clientWidth,
            windowHeight: document.body.clientHeight,
            appList: [],
            elements: [],
            tkpmList: [
                {
                id: "tkpm1",
                appName: "tkpm1",
                url: "/home/tkpm",
                pic: "http://43.142.45.115:9000/famousbuild/tkpm/TKPMhz.jpg",
                state: 1,
                description: "直接在revit中输入荷载，支持填充墙自动导荷、支持特殊构件信息输入",
            },
            {
                id: "tkpm2",
                appName: "tkpm2",
                url: "/home/tkpm",
                pic: "http://43.142.45.115:9000/famousbuild/tkpm/TKPMpj.jpg",
                state: 1,
                description: "一键计算，在revit中直接查看钢筋配筋值",
            },
            {
                id: "tkpm3",
                appName: "tkpm3",
                url: "/home/tkpm",
                pic: "http://43.142.45.115:9000/famousbuild/tkpm/TKPMnd.jpg",
                state: 1,
                description: "各工况下的内力直接在revit中显示",
            },
            {
                id: "tkpm4",
                appName: "tkpm4",
                url: "/home/tkpm",
                pic: "http://43.142.45.115:9000/famousbuild/tkpm/tkpmsz.png",
                state: 1,
                description: "所有参数输入界面与常规软件保持一致",
            },        
            ],
            userInfo: {}
        }
    },
    /**
     * 用来封装计算属性 有缓存功能  类似于computed
     */
    getters: {
        getMenuItem(state) {
            const menuItem = state.defaultMenu;
            if (Object.keys(state.userInfo).length > 0) {
                let dynamicdeMenu = menuItem[0].children.concat(state.loginMenu);
                console.log(state.userInfo.userRole == 1)
                if (state.userInfo.userRole == 1) {
                    console.log(state.adminMenu)
                    dynamicdeMenu = dynamicdeMenu.concat(state.adminMenu)
                }
                menuItem[0].children = dynamicdeMenu;
            } else {
                let removeItem = [].concat(state.loginMenu, state.adminMenu);
                let menuItemChildren = menuItem[0].children;
                for (let i = 0; i < removeItem.length; i++) {
                    for (let j = 0; j < menuItemChildren.length; j++) {
                        if (removeItem[i].name == menuItemChildren[j].name) {
                            menuItemChildren.splice(j, 1)
                        }
                    }
                }
            }
            console.log("获取当前的所有路由url:",menuItem)
            return menuItem;
        },
        getAppList(state) {
            return state.appList
        },
        getElements(state) {
            return state.elements
        },
        getUserInfo(state) {
            return state.userInfo;
        },
        getRebarMenu(state) {
            let userId = state.userInfo.userId || "15642785459861";
            state.rebarMenu.forEach(parentItem => {
                parentItem.children.forEach(item => {
                    let urlAry = item.url.split("/");
                    urlAry[urlAry.length - 1] = "userId=" + userId;
                    item.url = urlAry.join("/")
                })
            });
            return state.rebarMenu;
        }
    },
    /**
     * 编辑业务逻辑  类似于methods
     */
    actions: {
        setWindowSize(windowSizeParams) {
            this.windowWidth = windowSizeParams[0];
            this.windowHeight = windowSizeParams[1];
        },
        setAppList() {
            return new Promise((resolve, reject) => {
                if (this.appList.length == 0) {
                    getAction(getListUrl.allAppList).then(res => {
                        console.log(res)
                        if (res.success) {
                            let componentData = [];
                            let elements = [];
                            res.result.forEach((parent) => {
                                if (!parent.parentId) {
                                    parent.children = [];
                                    res.result.forEach((child) => {
                                        if (parent.id == child.parentId) {
                                            parent.children.push(child);
                                        }
                                    });
                                    if (parent.type == 1) {
                                        componentData.push(parent);
                                    } else if(parent.type == 2 || parent.type == 3) {
                                        elements.push(parent)
                                    }
                                }
                            });
                            this.appList = componentData;
                            this.elements = elements;
                            resolve()
                        } else {
                            reject(res.message)
                        }
                    }).catch(error => {
                        reject(error)
                    })
                } else {
                    resolve()
                }
            })
        },
        // 设置用户信息
        setUserInfo(userinfo) {
            console.log("设置用户信息", userinfo)
            this.userInfo = userinfo || {};
        },
        setUserAndDynamicRouter(userinfo, router) {
            this.userInfo = userinfo || {};
            this.setDynamicRouter(router)
        },
        // 动态注册路由
        setDynamicRouter(router) {
            console.log("动态添加&&删除路由")
            let dynamicMeun = [].concat(this.loginMenu);
            if (Object.keys(this.userInfo).length > 0) {
                // 注册路由
                console.log(this.userInfo)
                if (this.userInfo.userRole == 1) {
                    dynamicMeun = dynamicMeun.concat(this.adminMenu);
                };
                console.log("添加的路由数据",dynamicMeun);
                dynamicMeun.forEach(item => {
                    let routerConfig = {
                        path: item.path,
                        name: item.name,
                        meta: { title: item.title },
                        component: () => import(`@/views/${item.component}.vue`),
                        children: []
                    }
                    if (item.parent) {
                        router.addRoute(item.parent, routerConfig)
                    } else {
                        router.addRoute(routerConfig)
                    }
                })
            } else {
                dynamicMeun = dynamicMeun.concat(this.adminMenu);
                // 删除路由
                dynamicMeun.forEach(item => {
                    router.removeRoute(item.name)
                })
            }
        },
        // 设置用户过期时间
        setUserExpirationDate(mapData) {
            for(let key in mapData) {
                this.userInfo[key] = mapData[key];
            }
        }
    }
})

export default useMain;