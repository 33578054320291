<template>
  <a-modal
    v-model:visible="visible"
    :maskClosable="false"
    :footer="null"
    :width="1100"
  >
    <h2>关于我们</h2>
    <div class="content">
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;上海图启建筑科技股份有限公司&nbsp;结构计算网(famousbuild.com)致力于为工程师提供建筑、结构、设备全专业在线计算服务,结构计算网开发的中小型工具类计算软件填补了市场的空缺,10多年来网站从提供功能单一的构件计算向设计选型计算、智能建造计算、多专业综合计算方向发展,形成了以早期决策型计算为主的产品主线.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;网站屡获创业比赛大奖，包括2015上海市创业新秀入围奖、静安区十佳创业新秀等称号，研发成果受到上海市、区级专家和领导好评,在房地产和建筑行业，结构计算网已成为数百万名工程师工作必备的云工具箱。<br>
      </p>
      <p>
        <span class="bold">建筑业云计算先行者</span><br>
        &nbsp;&nbsp;&nbsp;&nbsp;结构计算网自钱鑫先生2003年创建起即采用了在线计算的服务方式，至2006年，网站所提供的计算功能已经基本覆盖常用结构计算领域，同年增加了计算书存储系统，结构计算网经过数十年的研发和积累，拥有了自主研发的网页绘图技术、大规模伺服计算技术、平面及板壳有限元脚本函数库等关键技术，解决了以互联网作为载体进行建筑计算的技术瓶颈，不断创新成就了结构计算网在建筑业云计算领域的先行者地位。
      </p>
      <p>
        <span class="bold">早期决策型计算</span><br>
        &nbsp;&nbsp;&nbsp;&nbsp;由结构计算网主推的“早期决策型计算”弥补了业界验证性计算软件的不足，突出了概念设计阶段的重要性，让建筑做法和截面在项目前期通过智能计算确定下来，减少了工作的盲目性。<br>
        &nbsp;&nbsp;&nbsp;&nbsp;早期决策型计算在BIM工程中起到了关键作用，它充分实现了先计算后表达的BIM工作流程；早期决策型计算也被广泛用于建筑可行性分析、旧城改造、政府决策等领域，它是集建造规划、专业设计、咨询优化于一体的综合性计算服务，众多工程师已经通过我们的研究成果获益。
      </p>
      <p>
        <span class="bold">感谢支持网站发展的团体和个人</span><br>
        &nbsp;&nbsp;&nbsp;&nbsp;植根工程师群体，为广大同行解决实际问题是结构计算网设立的初衷，网站将以更加开放的姿态结交各方朋友共同发展、互利共赢，共创美好未来。
      </p>
    </div>
  </a-modal>
</template>

<script>
import { ref } from "vue";
export default {
    name:"AboutUs",
    setup() {
        const visible = ref(false);
        const showModal = () => {
          visible.value = true;
        }
        return {
            visible,
            showModal
        }
    }
};
</script>

<style lang="less" scoped>
.content {
  height: 500px;
  overflow: auto;
  //   margin-top: 20px;
}
.bold {
  font-weight: 700;
}
p {
  line-height: 2;
}
</style>