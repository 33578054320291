<template>
  <a-row type="flex">
    <a-col flex="300px" v-show="windowWidth" class="logo-box" @click="backHome">
      <img src="@/assets/logo.png" alt="" class="logo-img" />
    </a-col>
    <a-col flex="auto">
      <div class="meun">
        <!-- 导航菜单区域 -->
        <!-- <a-menu :selectedKeys="current" mode="horizontal" @click="routerJump">
          <a-menu-item
            v-for="item in menuItem"
            :key="item.name"
            :path="item.path"
          >
            <template #icon>
              <component :is="$icons[item.icon]" />
            </template>
            <span>{{ item.title }}</span>
          </a-menu-item>
        </a-menu> -->

        <a-menu :selectedKeys="current" mode="horizontal" @click="routerJump">
          <!-- 遍历菜单 -->
          <template v-for="item in menuItem">
            <!-- 判断是否有子菜单 -->
            <a-sub-menu
              v-if="item.children && item.children.length"
              :key="item.name"
              :title="item.title"
            >
              <template #icon>
                <component :is="$icons[item.icon]" />
              </template>
              <!-- 子菜单项 -->
              <a-menu-item
                v-for="subItem in item.children"
                :key="subItem.name"
                :path="subItem.path"
              >
                <template #icon>
                  <component :is="$icons[subItem.icon]" />
                </template>
                <span>{{ subItem.title }}</span>
              </a-menu-item>
            </a-sub-menu>

            <!-- 单一菜单项 -->
            <a-menu-item v-else :key="item.name" :path="item.path">
              <template #icon>
                <component :is="$icons[item.icon]" />
              </template>
              <span>{{ item.title }}</span>
            </a-menu-item>
          </template>
        </a-menu>

        <!-- <a-sub-menu key="sub1">
          <template #icon>
            <setting-outlined />
          </template>
          <template #title>Navigation Three - Submenu</template>
          <a-menu-item-group title="Item 1">
            <a-menu-item key="setting:1">Option 1</a-menu-item>
            <a-menu-item key="setting:2">Option 2</a-menu-item>
          </a-menu-item-group>
          <a-menu-item-group title="Item 2">
            <a-menu-item key="setting:3">Option 3</a-menu-item>
            <a-menu-item key="setting:4">Option 4</a-menu-item>
          </a-menu-item-group>
        </a-sub-menu> -->

        <!-- 登录区域 -->
        <a-space class="user-info" v-if="isLogin">
          <!-- <a-button class="login-btn" @click="onLogin('login')">
            登录
          </a-button>
          <a-button class="login-btn" type="primary" @click="onLogin('setup')">免费使用</a-button> -->
          <a-button class="login-btn" type="primary" @click="onLogin('login')"
            >登录 | 注册</a-button
          >
        </a-space>

        <!-- 用户区域 -->
        <a-space class="user-info" v-else>
          <a-dropdown>
            <div>
              <span v-if="windowWidth" class="user-name">欢迎您会员</span>
              <a-avatar style="background-color: #1890ff">
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </div>

            <template #overlay>
              <a-menu @click="onUserOperate">
                <a-menu-item key="user">
                  <SettingOutlined />
                  <span class="user-info-text">用户中心</span>
                </a-menu-item>
                <a-menu-item key="pay">
                  <AlipayOutlined />
                  <span class="user-info-text">会员续费</span>
                </a-menu-item>
                <a-menu-item key="exit">
                  <LogoutOutlined /><span class="user-info-text">退出</span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-space>
      </div>

      <!-- <div v-if="!windowWidth">小屏菜单</div> -->
    </a-col>
  </a-row>
</template>

<script>
import { reactive, computed, ref, onMounted, getCurrentInstance } from "vue";
import { useMain } from "@/store/index";
import { useRouter, useRoute } from "vue-router";
import LocalStoreAPI from "@/utils/localStoreUtil";

export default {
  name: "Header",
  setup() {
    const { $message } =
      getCurrentInstance().appContext.config.globalProperties;
    const useStoreMain = useMain();
    const router = useRouter();
    const route = useRoute();
    const current = computed(() => {
      return [route.name];
    });
    const userId = useStoreMain.userId;
    const windowWidth = computed(() => {
      return useStoreMain.windowWidth >= 1100;
    });

    const menuItem = computed(() => {
      let MenuItemData = useStoreMain.getMenuItem.find(
        (item) => item.name == "home"
      );
      if (MenuItemData) {
        MenuItemData = MenuItemData.children.filter(
          (item) => item.name != "user" && item.name != "pay"
        );
        // 结构计算
        let index = MenuItemData.findIndex((item) => item.name == "componentCalculation")
        let structure = {
          name: "structure",
          title: "构件计算",
          icon: "ProjectOutlined",
          children: MenuItemData.filter((item) => item.name == "componentCalculation" || item.name == "finiteElements")
        };
        MenuItemData.splice(index, 2, structure);
      }
      console.log("菜单", MenuItemData);
      return MenuItemData;
    });

    const isLogin = computed(() => {
      let userId = useStoreMain.userInfo.id;
      return userId ? false : true;
    });
    const login = ref(null);
    const backHome = () => {
      console.log("返回首页");
    };

    /**导航栏点击 */
    const routerJump = ({ item, key, keyPath }) => {
      console.log("导航栏点击", item, key, keyPath);
      console.log({ name: key });
      if (key == "feedBack") {
        // router.push({ name: key, params: { page: `page` } });
        router.replace({ name: key });
      } else {
        router.push({ name: key });
      }
    };

    const onLogin = (type) => {
      router.push({ name: "login", params: { type: `type=${type}` } });
    };

    const onUserOperate = ({ key }) => {
      console.log(`Click on item ${key}`);
      if (key == "user" || key == "pay") {
        router.push({
          name: key,
          query: {
            ...route.query,
          },
        });
      } else if (key == "exit") {
        LocalStoreAPI.remove("ACCESS_TOKEN");
        LocalStoreAPI.remove("USER_INFO");
        useStoreMain.setUserAndDynamicRouter(null, router);
        console.log(route.name);
        console.log(["feedBack", "user", "pay"].includes(route.name));
        if (["feedBack", "user", "pay"].includes(route.name)) {
          router.push({
            name: "homepage",
            query: {
              ...route.query,
            },
          });
        } else {
          router.go(0);
        }
        $message.success("登出成功");
      }
    };

    return {
      login,
      windowWidth,
      menuItem,
      current,
      isLogin,
      userId,
      backHome,
      routerJump,
      onLogin,
      onUserOperate,
    };
  },
};
</script>

<style lang="less" scoped>
.logo-box {
  text-align: center;
  .logo-img {
    // width: 100%;
    height: 64px;
  }
}
.user-info {
  margin-right: 100px;
}
.meun {
  display: flex;
  justify-content: space-between;
}
.user-info-text {
  margin-left: 8px;
}
.user-name {
  margin-right: 4px;
}
.login-btn {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.use-old-web {
  margin-right: 10px;
}
</style>