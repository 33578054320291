<template>
  <div class="home">
    <div class="home-box">
      <HomeBanner />
    </div>
    <div class="structural-calculations">
      <div class="structural-calculations-content">
        <HomeTabs
          title="构件计算"
          type="componentCalculation"
          :dataSource="useStoreMain.appList"
          :grid="grid"
          :pagination="pagination"
        />
        <HomeTabs
          title="TKPM结构正向设计"
          type="tkpm"
          :dataSource="useStoreMain.tkpmList"
          :grid="grid1"
          :pagination="pagination"
        />
        <HomeTabs
          title="钢筋翻样表"
          type="rebarMaterials"
          :dataSource="useStoreMain.rebarList"
          :grid="grid1"
          :pagination="pagination"
        />
        <HomeTabs
          title="有限元计算"
          type="finiteElements"
          :dataSource="useStoreMain.elements"
          :grid="grid"
          :pagination="pagination"
        />
        <HomeTabs
          title="钢筋施工插件"
          type="revit"
          :dataSource="useStoreMain.revitList"
          :grid="grid1"
          :pagination="pagination"
        />
      </div>
    </div>
    <HomeFooter />
  </div>
</template>

<script>
import { ref, computed, onBeforeMount, reactive } from "vue";
import { useMain } from "@/store/index";
import { finiteAppList, rebarList } from "@/utils/dataUtils";
import HomeTabs from "./HomeTabs.vue";
import HomePage1 from "@/views/home/HomePage1.vue";
import HomeBanner from "@/views/home/HomeBanner.vue";
import HomeFooter from "@/views/home/HomeFooter.vue";
export default {
  name: "Home",
  components: {
    HomeTabs,
    HomePage1,
    HomeBanner,
    HomeFooter
  },
  setup() {
    const useStoreMain = useMain();


    const pagination = {
      onChange: (page) => {
        console.log(page);
      },
      pageSize: 5,
    };
    const grid = { gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 5, xxl: 5 };
    const grid1 = {
      gutter: 16,
      xs: 1,
      sm: 1,
      md: 1,
      lg: 2,
      xl: 2,
      xxl: 2,
      xxxl: 2,
    };

    return {
      useStoreMain,
      pagination,
      grid,
      grid1,
      finiteAppList,
      rebarList
    };
  },
};
</script>

<style lang="less" scoped>
.home {
  // background-color: #f7f7f7;
  background-color: rgb(243, 247, 251);
  
  height: calc(100vh - 64px);
  overflow-y: auto;

  .commont-box {
    width: 100%;
    background-color: #1890ff;
  }
  .commont-content {
    max-width: 1400px;
    margin: 0 auto;
  }

  .home-box {
    width: 100%;
    height: 100%;
    // background-image: url("@/assets/home1.jpg");
    background-size: cover;
    position: relative;
  }

  .structural-calculations {
    width: 100%;

    .structural-calculations-content {
      max-width: 1400px;
      margin: 0 auto;
      // overflow: hidden;

      .double-line {
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .structural-calculations-title {
        .px2vw(margin-right, 100);
        .px2vw(font-size, 26);
        font-weight: 900;
      }
    }
  }


}
</style>