<template>
  <div class="commont-box">
    <div class="footer-content commont-content">
      <a-row>
        <a-col span="10">
          <p class="footer-text">关于我们</p>
          <p class="footer-text">
            图启建科旗下结构计算网致力于提供先进的数字钢筋解决方案。
            公司成立10年来，服务于中海油、中建、中交等多家央企，承接了众多软件研发项目，本网站基于部分研发成果进行组合式创新，
            围绕数字钢筋形成了一套钢筋设计计算、钢筋建模、钢筋识别、钢筋翻样和智慧加工等完整的软件产品。          
          </p>
        </a-col>
        <a-col span="1">
        </a-col>
        <a-col span="9">
          <p class="footer-text">商务咨询 | 定制开发</p>
          <p class="footer-text">欢迎基于我们的Revit钢筋施工插件、在线钢筋翻样表及PDF识别套件、智能加工为您的企业提供端到端软件定制开发服务。</p>
          <p class="footer-text">
           私有数据 | 企业登录 | 格式与输出 | 算法定制 | 机器接入 | 其它需求
          </p>
          <p class="footer-text">
           <a><MailOutlined />电话:&nbsp;&nbsp;021-50116038</a>&nbsp; &nbsp;<a><MailOutlined />微信:&nbsp;&nbsp;famousbuild</a>
          </p>
        </a-col>
        <a-col span="4" align=center>
          <p class="footer-text">问题解答 | 客户服务</p><center>
          <p class="footer-text">
            <img src=http://36.150.110.246:8091/pic/famousbuild1.jpg width=120 />            
          </p></center>
        </a-col>        
      </a-row>
      <a-row>
        <a-col span="24">
          <p class="footer-conpyright">
            Copyright © 2025-上海图启建筑科技股份有限公司版权所有
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              style="color: #000"
              >&nbsp;沪ICP备15030720号-2</a>
          </p>
        </a-col>
        </a-row>
    </div>
  </div>
  <AboutUs ref="aboutUs" />
</template>

<script>
import { ref } from "vue";
import AboutUs from "@/views/home/AboutUs.vue";
export default {
  name: "HomeFooter",
  components: {
    AboutUs,
  },
  setup() {
    const aboutUs = ref(null);

    const aboutMe = () => {
      aboutUs.value.showModal();
    };

    return {
      aboutUs,
      aboutMe,
    };
  },
};
</script>

<style lang="less" scoped>
.commont-box {
  width: 100%;
  background-color: #1890ff;
  margin-top: 20px;
}
.commont-content {
  max-width: 1200px;
  margin: 0 auto;
}
.footer-content {
  padding: 20px 0;
  .footer-text {
    color: #fff;

    a {
      color: #fff;
    }

    a:hover {
      color: #000;
    }
  }
  .footer-conpyright {
    .px2vh(margin-top, 16);
    text-align: center;
    margin-bottom: 0;
  }
}
</style>